<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    :multiple="$attrs.multiple"
    :hint="$attrs.hint"
    :persistent-hint="$attrs.persistent - hint"
  >
    <v-select
      slot-scope="{ errors, valid }"
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
      outlined="outlined"
      ref="validateSelectField"
    ></v-select>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    innerValue: "",
    hint: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("change", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    this.$validator.attach;
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
